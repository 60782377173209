import FlushingTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-redness/flushing/FlushingTemplate';

export default {
  title: 'Steps/Redness/Flushing/FlushingTemplate'
};

const createStory = props => () => ({
  components: { FlushingTemplate },
  storyProps: props,
  wrapStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template: `<div :style='$options.wrapStyles'>
    <flushing-template v-bind="$options.storyProps" />
  </div>`
});

export const Base = createStory({
  flushes: 'yes'
});
